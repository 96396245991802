<template>
  <section class="overview">
    <div v-if="showUpgradeView" class="card" style="margin-bottom: 48px">
      <div class="card-body">
        <h1 class="home-title" style="margin-bottom: 16px">{{ $t('upgrade_view_title') }}</h1>
        <p class="upgrade-view-description">
          {{ $t('upgrade_view_description') }}
        </p>
        <div class="upgrade-view-features-grid">
          <div class="upgrade-view-feature">
            <feather-icon id="feat_1" icon="UserIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_1') }}</span>
          </div>
          <div class="upgrade-view-feature">
            <feather-icon id="feat_2" icon="MapPinIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_2') }}</span>
          </div>
          <div class="upgrade-view-feature disabled-feature">
            <feather-icon id="feat_3" icon="CalendarIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_3') }}</span>
          </div>
          <div class="upgrade-view-feature disabled-feature">
            <feather-icon id="feat_4" icon="CreditCardIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_4') }}</span>
          </div>
          <div class="upgrade-view-feature disabled-feature">
            <feather-icon id="feat_5" icon="BoxIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_5') }}</span>
          </div>
          <div class="upgrade-view-feature disabled-feature">
            <feather-icon id="feat_6" icon="MonitorIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_6') }}</span>
          </div>
          <div class="upgrade-view-feature disabled-feature">
            <feather-icon id="feat_7" icon="UsersIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_7') }}</span>
          </div>
          <div class="upgrade-view-feature disabled-feature">
            <feather-icon id="feat_8" icon="TrendingUpIcon" size="32" />
            <span>{{ $t('upgrade_view_feat_8') }}</span>
          </div>
        </div>
        <div class="upgrade-banner">
          <h3 style="flex: 1">
            {{ $t('upgrade_plan_banner') }}
          </h3>
          <div class="upgrade-cta-buttons">
            <a
              class="btn btn-outline-secondary need-help-button stretch-button"
              :href="scheduleCallLink"
              target="_blank"
              rel="noopener noreferrer"
              >{{ $t('Schedule a call') }}</a
            >
            <router-link to="/subscribe" class="btn btn-toddl stretch-button">
              {{ $t('upgrade_plan_cta') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card full-page">
      <div class="card-header p-2">
        <b-row>
          <b-col md="9" xs="12">
            <h1 class="home-title mt-1 mb-1 block">
              {{ $t('Welcome') + `, ${$store.state.auth.user.name}!` }}
            </h1>
            <span class="block home-subtitle">{{ $t('You are ready') }}</span> <br />
            <span class="block home-subtitle"
              >{{ $t('Feedback') }}
              <a :href="'mailto:info@toddl.co?subject=' + this.$t('Subject')">info@toddl.co</a>
            </span>
          </b-col>
          <b-col class="header-image" cols="2">
            <img
              style="height: 175px"
              :src="require('@/assets/images/illustration/email.svg')"
              alt="Not found image"
            />
          </b-col>
        </b-row>
      </div>
      <div class="card-body inner-scroll">
        <NewSassBanner />
        <template v-if="activities.length > 0">
          <h2 class="mt-3 block">
            {{ $t('Your latest activities, camps and events') }}
          </h2>
          <div class="row pt-1">
            <ActivityCard
              v-for="activity in activities"
              :activity="activity"
              :key="activity.id"
            ></ActivityCard>
          </div>
        </template>
        <b-col
          v-if="activities.length === 0 && requestDone && canCreateLegacyActivities"
          cols="12"
          class="p-3"
        >
          <div class="create-activities">
            <span class="create-help">{{
              $t('Create an activity, camp or event to get started')
            }}</span>
            <b-row class="mb-4">
              <b-col md="4">
                <b-button size="md" @click="createActivity(1)">
                  <img src="@/assets/images/sidebar-icons/activities.svg" />
                  {{ $t('Create an activity') }}
                </b-button>
              </b-col>
              <b-col md="4">
                <b-button size="md" @click="createActivity(2)">
                  <img src="@/assets/images/sidebar-icons/camps.svg" />
                  {{ $t('Create a camp') }}
                </b-button>
              </b-col>
              <b-col md="4">
                <b-button size="md" @click="createActivity(3)">
                  <img src="@/assets/images/sidebar-icons/events.svg" />
                  {{ $t('Create an event') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </div>
    </div>
  </section>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

import ActivityCard from '@/components/ActivityCard'
import ActivityService from '@/services/ActivityService'
import NewSassBanner from '@/components/NewSassBanner'

export default {
  name: 'Home',
  components: {
    BRow,
    BCol,
    BButton,
    ActivityCard,
    NewSassBanner,
  },
  data() {
    return {
      ActivityService: new ActivityService(),
      activities: [],
      requestDone: false,
      scheduleCallLink: process.env.VUE_APP_SUBSCRIBE_FUNNEL_CALL_LINK,
      canCreateLegacyActivities: true,
    }
  },
  computed: {
    showUpgradeView() {
      return (
        this.$store.state.auth.user.plan === 'restricted' &&
        this.$store.state.auth.user.trial_ends_at
      )
    },
  },
  mounted() {
    this.getActivities()

    this.$posthog.onFeatureFlags(() => {
      if (
        this.$store.state.auth.user.has_saas_access &&
        !this.$posthog.isFeatureEnabled('allow-legacy-activities')
      ) {
        this.canCreateLegacyActivities = false
      }
    })
  },
  methods: {
    async getActivities() {
      this.requestDone = false
      const { data: activities } = await this.ActivityService.index({
        search: undefined,
        page: 0,
        per_page: 20,
      })

      this.activities = activities.sort((a, b) => {
        if (a.updated_at < b.updated_at) return 1

        if (a.updated_at > b.updated_at) return -1

        return 0
      })

      this.requestDone = true
    },
    createActivity(typeId) {
      this.$router.push(`/activities/${typeId}/create`)
    },
  },
}
</script>

<style scoped>
.upgrade-view-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.upgrade-view-features-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.upgrade-view-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 12px;
  border: 1px solid #6bc4c5;
  border-radius: 8px;
  color: #fff;
  background-color: #6bc4c5;
}

.disabled-feature {
  opacity: 0.5;
  border: 1px solid #555;
  color: #0e1a1a;
  background-color: transparent;
}

.upgrade-view-feature span {
  font-size: 1.15rem;
  text-align: center;
}

.upgrade-banner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.upgrade-banner h3 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.upgrade-cta-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;
}

.stretch-button {
  width: 100%;
}

.need-help-button {
  max-height: fit-content;
  align-self: flex-end;
  color: #4b64a5;
}

@media screen and (min-width: 648px) {
  .upgrade-view-features-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2rem;
  }

  .upgrade-banner {
    flex-direction: row;
    align-items: center;
  }

  .upgrade-cta-buttons {
    flex-direction: row;
    width: auto;
  }

  .need-help-button {
    max-width: fit-content;
  }

  .stretch-button {
    width: auto;
  }
}

@media screen and (min-width: 1568px) {
  .upgrade-view-features-grid {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

.create-activities button {
  display: block;
  width: 100%;
  margin-top: 2em;
}

.create-activities button img {
  filter: invert(100%);
  height: 25px;
  margin-right: 1em;
}

.create-help {
  font-size: 1.2em;
  text-align: center;
}

.home-subtitle {
  font-size: 1.05em;
  max-width: 50%;
  color: #555;
}
.create-activities {
  margin-top: 2em;
}

@media (max-width: 648px) {
  h2 {
    font-size: 1.3em;
    margin-top: 0.5em !important;
  }
  .home-title {
    margin-top: 0 !important;
  }
  .home-subtitle {
    font-size: 0.85em;
  }
  .card-header .col-7 {
    width: 100% !important;
  }
  .header-image {
    display: none;
  }

  .create-activities button {
    font-size: 0.9em;
  }

  .create-activities {
    margin-top: 0em;
  }
}
</style>
